
@import '~@angular/material/theming';

@import '~animate.css/animate.min.css';


:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #ead6ff;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #aaa;
  --gray-dark: #343a40;
  --primary: rgb(158, 140, 142);
  --secondary: #4d3eab;
  --success: #28a745;
  --info: #ccc;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: rgba(244, 244, 237, 0.418);
  --dark: #151515;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 991px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 2300px;
  --font-family-sans-serif: "Rubik", 'Montserrat', sans-serif;
  --font-family-monospace: "Open Sans", monospace;
  --font-family: 'Montserrat', sans-serif;
  --font-family: 'Rubik', sans-serif;
}


$custom-typography: mat-typography-config(
  $font-family: 'Rubik,Montserrat, monospace',
  $display-4:     mat-typography-level(112px, 112px, 300),
  $display-3:     mat-typography-level(56px, 56px, 400),
  $display-2:     mat-typography-level(45px, 48px, 400),
  $display-1:     mat-typography-level(34px, 40px, 400),
  $headline:      mat-typography-level(24px, 32px, 400),
  $title:         mat-typography-level(20px, 32px, 500),
  $subheading-2:  mat-typography-level(16px, 28px, 400),
  $subheading-1:  mat-typography-level(15px, 24px, 400),
  $body-2:        mat-typography-level(14px, 24px, 500),
  $body-1:        mat-typography-level(14px, 20px, 400),
  $caption:       mat-typography-level(12px, 20px, 400),
  $button:        mat-typography-level(16px, 16px, 500),
  $input:         mat-typography-level(inherit, 1.625, 400)
);

$screen-sm: 576px;
$screen-md: 991px;
$screen-lg: 1200px;
$screen-xl: 2300px;


body{
  font-family: 'Open Sans', sans-serif;
  font-size:18px;
  line-height:1.6;

  @media screen and (min-width:$screen-sm){
      font-size:16px;
  }

  @media screen and (min-width:$screen-lg){
      font-size:18px;
  }

  @media screen and (min-width:$screen-xl){
      font-size:20px;
  }
}



h1 { font-size: 1.0rem;}
h2 { font-size: 1.3rem; }
h3 { font-size: 1.1rem; }
h4 { font-size: 0.8rem; }
h5 { font-size: 1rem; }

@media screen and (min-width:$screen-sm){
  h1 { font-size: 2rem;}
  h2 { font-size: 1.8rem; }
  h3 { font-size: 1.5rem; }
  h4 { font-size: 1.2rem; }
  h5 { font-size: 1rem; }
}


@include mat-base-typography($custom-typography);

// Override typography for a specific Angular Material components.
@include mat-checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

@include mat-core($custom-typography);

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
::ng-deep .mat-snack-bar-handset .mat-snack-bar-container{
  margin-bottom: 60px !important;
  max-height: 20px;
  background: #e21b6c;
  }
::ng-deep .mat-snack-bar-handset .mat-simple-snackbar-action{
  background: #ffffff;
  }



  .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px 4px 0 0;
    padding: 0 .75em 0 .75em!important;
}

a {
  color: #e21b6c;
  text-decoration: none;
  background-color: transparent;
}

.tp-container{
  background-color: #f8f6f6 !important;
}

a:hover {
  color: #b30748;
  text-decoration: underline;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

.btn-primary {
  color: #fff;
  background-color:#e21b6c!important;
  border-color: #e21b6c!important;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-white {
  color: #e21b6c;
  background-color:white;
  border-color: #e21b6c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}


.btn-primary:hover {
  color: #fff;
  background-color: #d80857;
  border-color: #e21b6c;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #e21b6c;
  border-color: #e21b6c;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #e21b6c;
  border-color: #bf074d;
}

.btn-outline-primary {
  color: #e21b6c;
  border-color: #e21b6c;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.btn-outline-primary:hover {
  color: #fff;
  background-color: #e21b6c;
  border-color: #e21b6c;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 16, 103, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #e21b6c;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #e21b6c;
  border-color: #e21b6c;
}

.btn-link {
  font-weight: 400;
  color: #e21b6c;
  text-decoration: none;
}

::ng-deep .mat-tab-label-active {
  background-color: #e21b6c !important;
  color: white;
}

.btn-link:hover {
  color: #b30748;
  text-decoration: underline;
}

.btn-secondary {
  color: #fff;
  background-color: #150433;
  border-color: #150433;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #150433;
  border-color: #150433;
}

.btn-outline-secondary {
  color: #150433;
  border-color: #150433;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #150433;
  border-color: #150433;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #150433;
  background-color: transparent;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #150433;
  border-color: #150433;
}

.btn-dark {
  color: #fff;
  background-color: #30124E;
  border-color: #151515;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #e21b6c;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #e21b6c;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #e21b6c;
  background-color: #e21b6c;
  box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #fb8bb5;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #fff;
  background-color: #fcbcd4;
  border-color: #fcbcd4;
  box-shadow: none;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: #e21b6c;
  background-color: #e21b6c;
  box-shadow: none;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #e21b6c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #e21b6c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #e21b6c;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #e21b6c;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #e21b6c;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #b30748;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(246, 16, 103, 0.25);
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #e21b6c;
  border-color: #e21b6c;
}

.badge-primary {
  color: #fff;
  background-color: #e21b6c;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #e21b6c;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(246, 16, 103, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #150433;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(190, 184, 199, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #30124E;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #e21b6c;
  transition: width 0.6s ease;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #e21b6c;
  border-color: #e21b6c;
}

.bg-primary {
  background-color: #e21b6c !important;
}
.bg-gray {
  background-color: #f8f6f6 !important;
}
.bg-black-friday {
  background-color: #000000 !important;
}
.bg-pink {
  background-color: rgb(247, 239, 255) !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #e21b6c !important;
}

.bg-secondary {
  background-color: #150433!important;
}

.border-primary {
  border-color: #e21b6c !important;
}

.border-secondary {
  border-color: #150433 !important;
}

.bg-dark {
  background-color: #151515 !important;
}

.text-primary {
  color: #e21b6c !important;
}
.text-gray{
  color:  rgba(244, 244, 237, 0.418)!important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #b30748 !important;
}
.text-yellow{
  color:#FDCC0D!important;
}
.text-secondary {
  color: #150433 !important;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none!important;
}

@media(max-width: 991px) {

  .hide-on-focus { display: block!important; }

    ::ng-deep .mat-form-field:focus .mat-form-field-type-mat-input:focus  + .hide-on-focus {
        display: none!important;
    }



  body {
    font-family: "Rubik", 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color:#f8f6f6 !important;
    color: #30233D;
    text-align: left;
    margin: 56px 0 70px;
    overflow-anchor: none;
  }


}

.modal-backdrop{
  z-index:0;
}
@media(min-width: 992px) {

  body {
    margin: 0;
    font-family: "Rubik", 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #30233D;
    text-align: left;
    background-color:#f8f6f6;
    margin: 61px 0 100px;
    overflow-anchor: none;
  }
  footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
  }

}

/*CHECKBOX wrap break line*/
::ng-deep .mat-checkbox-layout {
  white-space: normal !important;
  }
  ::ng-deep .mat-radio-label {
  white-space: normal !important;
  }
  /*REMOVE borded on click from buttons*/
  ::ng-deep button:focus {
    outline: none !important;
    border: 0 !important;
  }

  button:focus, button:active:focus, button.active:focus {
    outline: none !important;
    outline-style: none !important;
    }

    .dropdown-menu {
      z-index: 25000 !important;
    }


    /*
 *  STYLE 15
 */

#style-15::-webkit-scrollbar
{
	width: 15px;
	background-color:  rgb(207, 204, 204);
  border-radius: 20px!important;
}

#style-15::-webkit-scrollbar-thumb
{
	border-radius: 20px!important;
	background-color: #FFF;
	background-image: -webkit-gradient(linear,
									   40% 0%,
									   75% 84%,
									   from(#e21b6c),
									   to(#e21b6c),
									   color-stop(.6,#e21b6c))
}

#style-16::-webkit-scrollbar
{
	width: 15px;
	background-color:  rgb(207, 204, 204);

}

#style-16::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  margin-top: 10px;
	background-color: #FFF;
	background-image: -webkit-gradient(linear,
									   40% 0%,
									   75% 84%,
									   from(#150433),
									   to(#150433),
									   color-stop(.6,#150433))
}


@media(min-width:320px) and (max-width:374px) {
  .mat-dialog-container{
    padding: 12px !important;
  }
}


